import * as React from 'react'
import * as css from './QuickSso.module.css'
import { useAuth0 } from '@auth0/auth0-react'
import { useSso } from 'hooks/useSso'
import newtab from 'utils/newtab'
import { MdLaunch } from 'react-icons/md'

const A = ({ title, cta }) => {
  const { user } = useAuth0()
  const url = useSso(title, user).url || cta.url

  return (
    <a className="button sm with-icon" href={url} {...newtab}>
      {title}
      <MdLaunch />
    </a>
  )
}

const QuickSso = ({ items }) => (
  <section className={`${css.root} container padding`}>
    {items.map((item, key) => <A {...item} key={key} />)}
  </section>
)

export default QuickSso
