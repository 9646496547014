import * as React from 'react'
import * as css from './HeroWithBg.module.css'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import SanityBlock from 'components/common/SanityBlock'

const HeroWithBg = ({ title, _rawDescription, image }) => (
  <section className={css.root}>
    {image && (
      <GatsbyImage
        className={css.img}
        image={getImage(image.asset)}
        alt={title}
        draggable="false"
      />
    )}

    <div className="container padding">
      <div className={`${css.txt} styled-corner-left styled-corner-right`}>
        <div className={`${css.inner} richtext`}>
          {title && <h1 className="h1 cps-blue">{title}</h1>}
          {_rawDescription && <SanityBlock body={_rawDescription} />}
        </div>
      </div>
    </div>
  </section>
)

export default HeroWithBg
