import * as React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { withAuthenticationRequired } from '@auth0/auth0-react'
import DefaultLayout from 'layouts/Default'
import LoadingLayout from 'layouts/Loading'
import HeroWithBg from 'components/common/HeroWithBg'
import QuickSso from 'components/pg-quote/QuickSso'
import QuotingTools from 'components/pg-quote/QuotingTools'

const App = ({ location }) => {
  const { sanityQuotePg: pg } = useStaticQuery(query)

  return (
    <DefaultLayout {...pg.seo}>
      <HeroWithBg {...pg.hero} />
      <QuickSso {...pg.sso} />

      <QuotingTools
        {...pg.sso}
        title="Quoting Tools by Lines of Business"
        location={location}
      />
    </DefaultLayout>
  )
}

const QuotePg = withAuthenticationRequired(App, {
  onRedirecting: () => <LoadingLayout />
})

export default QuotePg

const query = graphql`query QuotePg {
  sanityQuotePg {
    hero { ...hero }
    sso {
      description
      items {
        title
        _rawDescription
        _rawNotice
        cta { ...cta }
        additional {
          title
          url
          file { asset { url } }
        }
      }
    }
    seo {
      title
      description
    }
  }
}`
